<template>
<div id="sso-redirect">
  <div id="sso-redirect-loading">
    <van-loading color="#0094ff" vertical>加载中...</van-loading>
  </div>
  <van-skeleton title :row="3" />
  <van-skeleton title :row="4" />
  <van-skeleton title :row="5" />
  <van-skeleton title :row="2" />
  <van-skeleton title :row="3" />
</div>
</template>

<script>
import suiShenBanSSOService from './services/suiShenBanSSOService'
export default {
  name: "suiShenBanSSOView",
  mounted() {
    suiShenBanSSOService.getSPTUserInfo(this.$route.query.access_token);
  }
}
</script>
<style scoped>
div#sso-redirect{
  height: 100vh;
  width: 100vw;
  position: relative;
  padding: 1rem 0px;
  overflow: hidden;
}
div#sso-redirect-loading{
  position: absolute;
  z-index: 2;
  top:40vh;
  width: 100%;
}
div#sso-redirect div.van-skeleton{
  margin-bottom: 1rem;
}
</style>